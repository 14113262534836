import React, { useEffect, useState } from 'react';
import { Input, Spinner, useSnackbar } from '@components/common';
import { customerActions } from 'actions/customer';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import s from '../CreateCustomer/CreateCustomer.module.scss';
import { useAppDispatch } from 'hooks/redux';

import { ICustomer } from 'interfaces/customer.interface';
import { Link } from 'react-router-dom';
import useRouter from 'hooks/router';
import { Col, Container, Row, media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import Button from '@components/Button';
import { api } from 'helpers/auth-axios';
const validationSchema = Yup.object().shape({
    email: Yup.string().required('This field is required'),
    firstname: Yup.string().required('This field is required'),
    lastname: Yup.string().required('This field is required'),
    phone: Yup.string().required('This field is required'),
});

const EditCustomer = () => {
    const [openSnackbar] = useSnackbar();
    const router = useRouter();
    const [profileImage, setProfileImage] = useState<any>(null);
    const [uploadImage, setUploadImage] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    const [customer, setCustomer] = useState<ICustomer>();

    const { handleSubmit, setValue, getValues, formState, trigger } = useForm<ICustomer>({
        resolver: yupResolver(validationSchema),
    });

    const { errors, isSubmitting } = formState;

    async function onChange({ name, value }: { name: string; value: string }) {
        if (name === 'phone') {
            let phone = value;
            if (phone.length <= 3) {
                phone = phone.replace(/[- .]/g, '');
                // phone = phone.slice(0, 3) + '-';
            } else if (phone.length <= 7) {
                phone = phone.replace(/[- .]/g, '');
                phone = phone.slice(0, 3) + '-' + phone.slice(3, 6);
            } else if (phone.length >= 7) {
                phone = phone.replace(/[- .]/g, '');
                phone = phone.slice(0, 3) + '-' + phone.slice(3, 6) + '-' + phone.slice(6);
            }
            setValue(name as never, phone as never);
            await trigger(name as never);
        } else if (name === 'email') {
            setValue(name as never, value?.toLowerCase() as never);
            await trigger(name as never);
        } else {
            setValue(name as never, value as never);
            await trigger(name as never);
        }
    }

    const onSubmit = (data: ICustomer) => {
        api.put(`/customers/${router.query.id as string}`, {
            ...data,
            newPhoto: uploadImage === true ? profileImage : null,
        })
            .then((customer: any) => {
                if (customer) {
                    openSnackbar('Customer updated sucessfully');
                    router.navigate(`/customers/${customer.data._id}`);
                }
            })
            .catch((e: any) => {
                openSnackbar(e?.response?.data?.message);
            });
    };

    useEffect(() => {
        api.get(`/customers/${router.query.id as string}`).then((customer: any) => {
            setCustomer(customer.data.customer);
            setValue('firstname', customer.data.customer.firstname);
            setValue('lastname', customer.data.customer.lastname);
            setValue('phone', customer.data.customer.phone);
            setValue('address', customer.data.customer.address);
            setValue('address2', customer.data.customer.address2);
            setValue('city', customer.data.customer.city);
            setValue('zipcode', customer.data.customer.zipcode);
            setValue('email', customer.data.customer.email);
            setValue('updatePassword', '');
            setProfileImage(customer.data.customer.photo);
        });
    }, [dispatch, router.query.id, setValue]);

    const handleCapture = ({ target }: any) => {
        setUploadImage(true);
        const reader: any = new FileReader();
        reader.readAsDataURL(target.files[0]);
        reader.onload = () => {
            if (reader.readyState === 2) {
                setProfileImage(reader.result);
            }
        };
    };
    return (
        <>
            <MainContainer>
                <Row>
                    <Col lg={12}>
                        {customer && (
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <TitleContainer>
                                    <TitleBody>
                                        <CustomLink to="/customers" className="mr-6">
                                            <Icon className="fal fa-times"></Icon>
                                        </CustomLink>
                                        <Title>Edit Customer</Title>
                                    </TitleBody>
                                    <AddButton>
                                        <Button
                                            disabled={isSubmitting}
                                            label="Save"
                                            bgtype={'secondary'}></Button>
                                    </AddButton>
                                </TitleContainer>
                                <Card>
                                    <FormContent>
                                        <Left>
                                            <InfoTitle>General Info</InfoTitle>
                                            <Circle>
                                                {/* <CircleLabel>
                                                    <NameCircle>
                                                        {(getValues('firstname')
                                                            ? getValues('firstname').charAt(0)
                                                            : '') +
                                                            (getValues('lastname')
                                                                ? getValues('lastname').charAt(0)
                                                                : '')}
                                                    </NameCircle>
                                                </CircleLabel> */}
                                                <CircleLabel
                                                    htmlFor="faceImage"
                                                    aria-label="upload picture">
                                                    <NameCircle
                                                        src={
                                                            uploadImage == true
                                                                ? profileImage
                                                                : `https://profile-images-exclusively-s3-prd.s3.amazonaws.com/${profileImage}`
                                                        }></NameCircle>
                                                </CircleLabel>
                                                <FileInput
                                                    accept="image/jpeg/png"
                                                    id="faceImage"
                                                    type="file"
                                                    onChange={handleCapture}
                                                />
                                            </Circle>
                                            <InputField>
                                                <Input
                                                    label="Email"
                                                    name="email"
                                                    // readOnly={true}
                                                    value={getValues('email')?.toLowerCase()}
                                                    onChange={onChange}
                                                    error={errors.email as any}
                                                />
                                            </InputField>
                                            <InputField>
                                                <Input
                                                    label="Password"
                                                    type="password"
                                                    name="updatePassword"
                                                    value={getValues('updatePassword')}
                                                    onChange={onChange}
                                                />
                                            </InputField>
                                            <InputField>
                                                <Input
                                                    label="First Name"
                                                    name="firstname"
                                                    value={getValues('firstname')}
                                                    onChange={onChange}
                                                    error={errors.firstname as any}
                                                />
                                            </InputField>
                                            <InputField>
                                                <Input
                                                    label="Last Name"
                                                    name="lastname"
                                                    value={getValues('lastname')}
                                                    onChange={onChange}
                                                    error={errors.lastname as any}
                                                />
                                            </InputField>
                                            <InputField>
                                                <Input
                                                    allowPhoneNumberOnly={true}
                                                    maxLength={12}
                                                    label="Phone"
                                                    name="phone"
                                                    value={getValues('phone')}
                                                    onChange={onChange}
                                                    error={errors.phone as any}
                                                />
                                            </InputField>
                                        </Left>
                                        <Right>
                                            <TabWrapper>
                                                <Tabs>
                                                    <TabsData className="tabs_defaultTab tabActive">
                                                        Additional Info
                                                    </TabsData>
                                                    {/* <TabsData className="tabs_defaultTab">
                                                        Coming Soon
                                                    </TabsData> */}
                                                </Tabs>
                                            </TabWrapper>
                                            <TabsContent>
                                                <div>
                                                    <InfoTitle>Address</InfoTitle>
                                                    <InputField>
                                                        <Input
                                                            label="Street address & number"
                                                            name="address"
                                                            value={customer.address}
                                                            onChange={onChange}
                                                        />
                                                    </InputField>
                                                    <InputField>
                                                        <Input
                                                            label="Suite number (optional)"
                                                            name="address2"
                                                            value={customer.address2}
                                                            onChange={onChange}
                                                        />
                                                    </InputField>
                                                    <CityField>
                                                        <City>
                                                            <Input
                                                                label="City"
                                                                name="city"
                                                                value={customer.city}
                                                                onChange={onChange}
                                                            />
                                                        </City>
                                                        <Input
                                                            label="Postal Code"
                                                            name="zipcode"
                                                            value={customer.zipcode}
                                                            onChange={onChange}
                                                        />
                                                    </CityField>
                                                </div>
                                            </TabsContent>
                                        </Right>
                                    </FormContent>
                                </Card>
                            </Form>
                        )}
                    </Col>
                </Row>
            </MainContainer>
        </>
    );
};

const MainContainer = styled(Container)`
    width: 100%;
    padding: 1.71rem;
`;

const Form = styled.form``;
const TitleContainer = styled.div`
display: flex;
justify-content: space-between;
margin-bottom: 1.75rem
align-items: center;
`;
const TitleBody = styled.div`
    display: flex;
    align-items: center;
`;
const CustomLink = styled(Link)`
    margin-right: 1.5rem /* 24px */;
`;

const Icon = styled.i`
    font-size: 2.25rem /* 36px */;
    line-height: 2.5rem /* 40px */;
    ${media.xs`
    font-size: 1.5rem
    `}
`;

const Title = styled.div`
    font-size: 1.78rem;
    font-weight: 600;
    ${media.xs`
    font-size: 1.3rem
    `}
`;
const AddButton = styled.div``;
const Card = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #ffffff;
    background-clip: border-box;
    border-radius: 0.42rem;
    box-shadow: 0rem 0rem 2.14rem 0rem rgb(82 63 105 / 5%);
    border: 0;
    margin-top: 1.5rem;
`;
const FormContent = styled.div`
    height: 100%;
    display: flex;
    ${media.xs`
display: block !important;
`}
    ${media.sm`
  display: block;
`}
  ${media.md`
  display: flex;
`} 
${media.lg`
  display: flex;
`}
`;
const Left = styled.div`
    padding: 1.71rem;
    border-right: 1px solid #e5e7eb;
    flex-basis: 28.57rem;
`;
const Right = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const InfoTitle = styled.div`
    margin-bottom: 1rem /* 16px */;
    font-size: 1.125rem /* 18px */;
    line-height: 1.75rem /* 28px */;
    font-weight: 500;
`;
const Circle = styled.div`
    justify-content: center !important;
    display: flex !important;
    margin-bottom: 3.5rem /* 24px */;
`;
const CircleLabel = styled.label`
    display: inline-block;
    flex-shrink: 0;
    position: relative;
    border-radius: 0.42rem;
    width: 3.14rem;
    height: 3.14rem;
`;
const NameCircle = styled.img`
    background: linear-gradient(-135deg, #eee, #dddfe1);
    color: #7e8299;
    width: 5.42rem;
    height: 5.42rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    line-height: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 0.42rem;
    font-size: 1.21rem;
    text-transform: capitalize !important;
    border-radius: 50%;
    cursor: pointer;
`;
const InputField = styled.div`
    margin-bottom: 1.71rem;
`;
const CityField = styled.div`
    margin-bottom: 1.71rem;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 1rem;
`;
const City = styled.div`
    grid-column: span 2 / span 2;
`;
const TabWrapper = styled.div`
    border-bottom: 0.07rem solid #e5e7eb;
    padding-left: 1.71rem;
    padding-right: 1.71rem;
`;

const Tabs = styled.ul`
    display: flex;
`;
const TabsData = styled.li`
    text-transform: capitalize;
    padding: 0 1.14rem;
    height: 3.42rem;
    display: flex;
    cursor: pointer;
    position: relative;
    font-size: 0.85rem;
    align-items: center;
    color: #9da0a4;
    font-weight: 500;
    color: #263446;
`;
const TabsContent = styled.div`
    padding: 1.71rem;
    flex: 1;
`;
const FileInput = styled.input`
    display: none;
`;
export default EditCustomer;
