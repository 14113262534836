import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { palette } from '../../../styled/common';
export interface DataProps {
    queryId?: string;
    fieldName?: string;
    name?: string;
    detailText?: string;
    icon?: any;
    link?: any;
}
const ContentList = ({ queryId, name, detailText, icon, link }: DataProps) => {
    const navigate = useNavigate();
    return (
        <Container>
            <Row>
                <Col style={{ padding: '0rem' }} lg={12} onClick={() => navigate(`${link}`)}>
                    <div className={`${queryId}`}>
                        <List>
                            <Icon
                                src={`https://profile-images-exclusively-s3-prd.s3.amazonaws.com/${icon}`}></Icon>
                            <InfoContainer>
                                <Name>{name}</Name>
                                <Details>{detailText}</Details>
                            </InfoContainer>
                        </List>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

const List = styled.div`
    padding: 0.85rem 1.71rem;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: start;
    border-bottom: 0.1rem solid ${palette.grey.lightest};
    cursor: pointer;
    &:hover {
        background-color: ${palette.grey.lightest};
    }
`;
const Icon = styled.img`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.2rem;
    height: 3.2rem;
    overflow: auto;
    background: red;
    border-radius: 2rem;
    background: linear-gradient(-135deg, ${palette.grey.lightest}, ${palette.grey.lightest});
    color: ${palette.silver};
`;
const InfoContainer = styled.div`
    flex-direction: column;
`;
const Name = styled.div`
    padding: 0 0.85rem;
    font-size: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
const Details = styled.div`
    padding: 0 0.85rem;
    font-size: 0.7rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
export default ContentList;
