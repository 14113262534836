import React, { useState, useEffect } from 'react';
import userImage from 'images/users/defaultAvatar.jpg';
import { Col } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { palette } from '../../../styled/common';
const Profile = () => {
    var _user: any = JSON.parse(localStorage.getItem('user') || '{}');

    return (
        <>
            <ProfileContent lg={12}>
                <Info>
                    <Image
                        src={`https://profile-images-exclusively-s3-prd.s3.amazonaws.com/${_user?.user?.photo}`}
                        alt=""
                    />

                    <div>
                        <Name>
                            {_user?.user_login == 'customer'
                                ? _user?.user?.firstname + ' ' + _user?.user?.lastname
                                : _user?.user?.name}
                        </Name>
                        <Type>{_user?.user_login}</Type>
                    </div>
                </Info>
                {/* <Icon className="fal fa-cog"></Icon> */}
            </ProfileContent>
        </>
    );
};

const ProfileContent = styled(Col)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #141923;
    padding: 24px;
    width: 100%;
    position: absolute;
    bottom: 0;
    background: #141923;
`;
const Info = styled.div`
    display: flex;
    align-items: center;
`;
const Image = styled.img`
    border-radius: 2rem;
    margin-right: 0.75rem;
    width: 3.14rem;
    height: 3.14rem;
`;
const Name = styled.div`
    font-weight: 500;
    padding-left: 0.45rem;
    color: ${palette.white};
`;
const Type = styled.div`
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding-left: 0.45rem;
    color: ${palette.white};
    font-weight: 300;
    text-transform: capitalize;
`;
const Icon = styled.i`
    color: ${palette.white};
    margin-right: 0.75rem;
    font-size: 1.4rem;
`;
export default Profile;
