import axios from 'axios';

export const publicApi = axios.create({
    baseURL: `https://app.getexclusively.com/api/`,
    headers: {
        'Content-Type': 'application/json',
    },
});
export const slotApi = axios.create({
    baseURL: `https://app.getexclusively.com/api/`,
    headers: {
        'Content-Type': 'application/json',
    },
    timeout: 1000 * 10, // Wait for 10 seconds
});

publicApi.interceptors.request.use(
    request => {
        var _userData = JSON.parse(localStorage.getItem('access-token') || '{}');
        request.headers.common.Authorization = `Bearer ${_userData}`;

        return request;
    },
    error => {
        return Promise.reject(error);
    }
);

publicApi.interceptors.response.use(
    response => {
        return response;
    },
    function (error) {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                return Promise.resolve(
                    publicApi
                        .post('/auth/refresh', {
                            refreshToken: JSON.parse(localStorage.getItem('user')).refreshToken,
                        })
                        .then(res => {
                            localStorage.setItem('user', JSON.stringify(res.data));
                            window.location.reload();
                        })
                );
            } catch (_error) {
                localStorage.removeItem('user');
                localStorage.removeItem('url');
                window.location.href = '/login';
                return Promise.reject(_error);
            }
        }

        return Promise.reject(error);
    }
);
slotApi.interceptors.request.use(
    request => {
        var _userData = JSON.parse(localStorage.getItem('access-token') || '{}');
        request.headers.common.Authorization = `Bearer ${_userData}`;

        return request;
    },
    error => {
        return Promise.reject(error);
    }
);

slotApi.interceptors.response.use(
    response => {
        return response;
    },
    function (error) {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                return Promise.resolve(
                    slotApi
                        .post('/auth/refresh', {
                            refreshToken: JSON.parse(localStorage.getItem('user')).refreshToken,
                        })
                        .then(res => {
                            localStorage.setItem('user', JSON.stringify(res.data));
                            window.location.reload();
                        })
                );
            } catch (_error) {
                localStorage.removeItem('user');
                localStorage.removeItem('url');
                window.location.href = '/login';
                return Promise.reject(_error);
            }
        }

        return Promise.reject(error);
    }
);
