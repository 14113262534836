import { useEffect, useState } from 'react';
import Button from '@components/Button';
import styled from 'styled-components';
import { Container, Row, Col, media } from 'styled-bootstrap-grid';
import { Input, LoaderOverlay, Select, Spinner, Switch, useSnackbar } from '@components/common';
import { businessTypes } from 'constants/data';
import moment from 'moment-timezone';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { palette } from '../../../styled/common';
import { api } from 'helpers/auth-axios';
const BusinessDetails = () => {
    const [profileImage, setProfileImage] = useState<any>(null);
    const [business, setBusiness] = useState<any>();

    const [uploadImage, setUploadImage] = useState<boolean>(false);
    const [loginUrl, setLoginUrl] = useState<any>();
    const [bookingUrl, setBookingUrl] = useState<any>();
    const [loading, setLoading] = useState(false);

    const [openSnackbar] = useSnackbar();
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('This field is required'),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    // @ts-ignore
    const { handleSubmit, setValue, formState, trigger } = useForm(formOptions);
    const { errors, isSubmitting } = formState;
    var _userData = JSON.parse(localStorage.getItem('user') || '{}');

    const getBusiness = () => {
        setLoading(true);

        api.get(`/businesses/${_userData?.user?.business_id?._id}`)
            .then((res: any) => {
                if (res) {
                    setLoading(false);
                    setBusiness(res.data);
                    setLoginUrl(window.location.origin + '/' + res.data._id + '/' + 'login');
                    setBookingUrl(`${window.location.origin}/book/${res.data._id}`);
                }
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };
    useEffect(() => {
        getBusiness();
    }, []);
    const onSubmit = (data: any) => {
        setLoading(true);
        const businessDetails = {
            ...data,
            newPhoto: uploadImage == true ? profileImage : null,
        };
        api.put(
            `businesses/${business._id!}`,
            uploadImage == true
                ? businessDetails
                : {
                      ...data,
                  }
        )
            .then((res: any) => {
                var _userData = JSON.parse(localStorage.getItem('user') || '{}');
                setLoading(false);
                if (res !== undefined) {
                    _userData.user.business_id.timezone = res.data.timezone;
                    localStorage.setItem('user', JSON.stringify(_userData));
                }
                openSnackbar('Updated successfully!');
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };
    async function onChange({ name, value }: { name: string; value: string }) {
        setValue(name as never, value as never);
        await trigger(name as never);
    }

    const handleCapture = ({ target }: any) => {
        setUploadImage(true);
        const reader: any = new FileReader();
        reader.readAsDataURL(target.files[0]);
        reader.onload = () => {
            if (reader.readyState === 2) {
                setProfileImage(reader.result);
            }
        };
    };

    // Set initial values
    useEffect(() => {
        setProfileImage(business?.photo);
        setTimeout(() => {
            setValue('name' as never, business?.name as never);
            setValue('phone' as never, business?.phone as never);
            setValue('business_url' as never, business?.business_url as never);
            setValue('timezone' as never, business?.timezone as never);
        });
    }, [business, setValue]);
    return (
        <>
            {loading && <LoaderOverlay />}

            {business && (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Wrapper>
                        <Row>
                            <CustomCol lg={6}>
                                <Card>
                                    <InputGroup>
                                        <Input
                                            label="What is the name of your business?"
                                            name="name"
                                            value={business.name}
                                            onChange={onChange}
                                            error={errors.name as any}
                                        />
                                    </InputGroup>
                                    <InputGroup>
                                        <Input
                                            label="What is your business phone number?"
                                            name="phone"
                                            value={business?.phone}
                                            onChange={onChange}
                                            error={errors.phone as any}
                                        />
                                    </InputGroup>
                                    <InputGroup>
                                        <Input
                                            label="What is your business website URL?"
                                            name="business_url"
                                            value={business?.business_url}
                                            onChange={onChange}
                                            error={errors.business_url as any}
                                        />
                                    </InputGroup>
                                    <InputGroup>
                                        <Input
                                            label="URL for customer login?"
                                            name="business_url"
                                            readOnly={true}
                                            value={loginUrl}
                                            onChange={onChange}
                                        />
                                    </InputGroup>
                                    <InputGroup>
                                        <Input
                                            label="Booking URL"
                                            readOnly={true}
                                            value={bookingUrl}
                                        />
                                    </InputGroup>
                                    <InputGroup>
                                        <Select
                                            disabled={true}
                                            value={businessTypes[0]?.label}
                                            label="What type of business are you running?"
                                            options={businessTypes}
                                        />
                                    </InputGroup>
                                    <InputGroup>
                                        <Select
                                            disabled={true}
                                            value={business?.timezone}
                                            label="Time Zone"
                                            onChange={(val: string) =>
                                                onChange({ value: val, name: 'timezone' })
                                            }
                                            options={moment.tz.names().map(timezone => {
                                                return { label: timezone };
                                            })}
                                        />
                                    </InputGroup>
                                    <InputGroup>
                                        <Button
                                            disabled={isSubmitting}
                                            type="submit"
                                            bgtype="secondary"
                                            label="Update"></Button>
                                    </InputGroup>
                                </Card>
                            </CustomCol>
                            <Col lg={6}>
                                <BusniessCard
                                    htmlFor="faceImage"
                                    aria-label="upload picture"
                                    style={{
                                        backgroundImage: `url(${
                                            uploadImage == true
                                                ? profileImage
                                                : `https://business-logos-exclusively-s3-prd.s3.amazonaws.com/${profileImage}`
                                        })`,
                                    }}></BusniessCard>
                                <Col lg={6}>
                                    <FileInput
                                        accept="image/jpeg/png"
                                        id="faceImage"
                                        type="file"
                                        onChange={handleCapture}
                                    />
                                </Col>
                            </Col>
                        </Row>
                    </Wrapper>
                </form>
            )}
        </>
    );
};
const Wrapper = styled(Container)`
    padding: 0rem;
`;
const CustomCol = styled(Col)`
    padding-bottom: 1rem;
`;
const Card = styled.div`
    background-color: ${palette.white};
    box-shadow: 0rem 0rem 2.14rem 0rem ${palette.grey.shadow};
    border-radius: 0.42rem;
    padding: 3rem 2rem 1.75rem 2rem !important;
`;
const BusniessCard = styled.label`
    display: flex;
    flex-direction: column;
    justify-content: end;
    border-radius: 0.5rem;
    color: ${palette.white};
    height: 15.71rem;
    width: 100%;
    background-position: 50%;
    background-size: cover;
    position: relative;
    padding: 1.71rem 1.71rem 1.14rem 1.14rem;
    z-index: 2;
    overflow: hidden;
    cursor: pointer;
`;
const Branding = styled.div`
    display: flex;
    align-items: center;
`;
const InputGroup = styled.div`
    margin-bottom: 1rem;
`;
const Cover = styled.div`
    border: 0.32rem solid ${palette.white};
    border-radius: 50%;
    object-fit: cover;
    width: 7rem;
    height: 7rem;
`;
const Logo = styled.img`
    border-radius: 50%;
    object-fit: cover;
    width: 100%;
    height: 100%;
`;
const BusniessName = styled.h1`
    font-weight: 600 !important;
    font-size: 1.5rem !important;
    line-height: 2rem !important;
    margin-left: 1rem !important;
`;
const SettingCard = styled.div`
    background-color: ${palette.light};
    box-shadow: 0rem 0rem 2.14rem 0rem ${palette.grey.shadow};
    border-radius: 0.42rem;
    padding: 1.26rem 2rem !important;
    margin-top: 1rem;
`;
const CardHeading = styled.h1`
    font-weight: 600 !important;
    font-size: 1.125rem !important;
    line-height: 1.75rem !important;
    margin-bottom: 1.5rem !important;
`;
const CardList = styled.div`
    align-items: center !important;
    cursor: pointer !important;
    display: flex !important;
    justify-content: space-between;
`;
const Divider = styled.hr`
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
    border: 0.1rem none none none solid ${palette.grey.lightest};
    border-top: none;
    border-right: none;
    border-left: none;
`;
const ProfileImage = styled.img`
    object-fit: cover;
    width: 19rem;
    height: 19rem;
    border-radius: 12rem;
    ${media.xs`
    width: 10rem;
    height: 10rem;
  `}
    ${media.sm`
    width: 15rem;
    height: 15rem;
  `}
    ${media.md`
	width: 19rem;
    height: 19rem;
  `}
    ${media.lg`
    width: 19rem;
    height: 19rem;
  `}
`;
const FileButton = styled.label`
    width: 100%;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${palette.secondary};
    border-radius: 0.5rem;
    color: white;
    text-transform: capitalize;
    cursor: pointer;
    margin-top: 0rem;
    margin-bottom: 0rem;

    ${media.xs`
    margin-top: 0rem;
    margin-bottom: 0.71rem;
  `}
    ${media.sm`
    margin-top: 0rem;
    margin-bottom: 0.71rem;
  `}
    ${media.md`
	margin-top: 0.71rem;
    margin-bottom: 0rem;
  `}
    ${media.lg`
    margin-top: 0.71rem;
  `}
`;

const FileInput = styled.input`
    display: none;
`;
export default BusinessDetails;
