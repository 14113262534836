import React, { useRef, useState, useEffect } from 'react';
import { scrollIntoView } from '../utils';
import User from '../../../images/users/defaultAvatar.jpg';

interface Option {
    label: string;
    value?: string | number;
    render?: string;
    id: string;
    icon?: any;
}

export type FocusDirection = 'up' | 'down' | 'pageup' | 'pagedown' | 'first' | 'last';

export interface InputProps {
    name?: string;
    type?: string;
    id?: string;
    value?: string | number;
    label?: string;
    className?: string;
    readOnly?: boolean;
    disabled?: boolean;
    options: Option[];
    slotLeft?: React.ReactNode;
    fullWidth?: boolean; // width 100%
    onBlur?(): void;
    onInput?(value: string): void;
    onChange?(value: any): void;
}

const Input = ({
    name,
    type,
    id,
    value,
    options,
    label,
    className,
    readOnly,
    disabled,
    slotLeft,
    fullWidth,
    onBlur,
    onInput,
    onChange,
    ...props
}: InputProps) => {
    const [searchValue, setSearchValue] = useState('');
    const [focusedOption, setFocusedOption] = useState<Option | null>(null);
    const [scrollToFocusedOption, setScrollToFocusedOption] = useState(false);
    const [isActive, setActive] = useState(false);
    const [isFocused, setFocused] = useState(false);
    const [inputValue, setInputValue] = useState<string | number>('');
    const [inputIcon, setInputIcon] = useState();
    const [inputId, setInputId] = useState<any>();

    useEffect(() => {
        setInputValue(value ?? '');
        setInputId(id);
    }, [value]);
    const inputRef = useRef<HTMLInputElement>(null);
    const focusedOptionRef = useRef<HTMLDivElement>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (inputRef.current && inputRef.current.value.length > 0) setActive(true);

        // Check if input value was a value rather than a label
        const selectedOption = options.find(option => option.value === inputValue);
        if (selectedOption) setInputId(selectedOption.id);
    }, []);

    useEffect(() => {
        if (dropdownRef.current && focusedOptionRef.current && scrollToFocusedOption) {
            scrollIntoView(dropdownRef.current, focusedOptionRef.current);
            setScrollToFocusedOption(false);
        }
    }, [scrollToFocusedOption, focusedOptionRef]);

    const focusInput = () => {
        if (!isFocused) {
            setActive(true);
            setFocused(true);
            if (inputRef.current) {
                inputRef.current.focus();
            }

            setTimeout(() => {
                if (focusedOptionRef.current && dropdownRef.current) {
                    scrollIntoView(dropdownRef.current, focusedOptionRef.current);
                }
            });
        } else {
            setFocused(false);
        }
    };

    const unfocusInput = (event: React.FocusEvent<HTMLInputElement>) => {
        setTimeout(() => {
            var target: any = event.relatedTarget;
            if (event.relatedTarget != null && target.tagName == 'INPUT') {
                event.relatedTarget = null;
            }
            if (!event.relatedTarget) {
                setFocused(false);
                if (inputRef.current && inputRef.current.value.length === 0) setActive(false);
            } else {
                focusInput();
            }

            if (onBlur) onBlur();
        }, 300);
    };
    useEffect(() => {
        setInputIcon(options[0].icon);
    }, []);
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
        setSearchValue(event.target.value);

        if (onInput) onInput(event.target.value);
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (!readOnly) {
            const validKeys = [' ', 'ArrowUp', 'ArrowDown', 'Enter'];

            if (validKeys.indexOf(event.key) !== -1) {
                event.preventDefault();

                if (event.key === 'ArrowDown') {
                    focusOption('down');
                }

                if (event.key === 'ArrowUp') {
                    focusOption('up');
                }

                if (event.key === 'Enter') {
                    if (!focusedOption) return;
                    selectOption(focusedOption);
                }
            }
        }
    };

    const focusOption = (direction: FocusDirection = 'first') => {
        if (!options.length) return;

        let nextFocus = 0; // handles 'first'
        let focusedIndex = options.indexOf(focusedOption!);
        if (!focusedOption) {
            focusedIndex = -1;
        }

        switch (direction) {
            case 'up':
                nextFocus = focusedIndex > 0 ? focusedIndex - 1 : options.length - 1;
                break;
            case 'down':
                nextFocus = (focusedIndex + 1) % options.length;
                break;
            default:
                break;
        }

        setScrollToFocusedOption(true);
        setFocusedOption(options[nextFocus]);
    };
    //label: string, value: string, render?: string
    const selectOption = (option: Option) => {
        if (option.render) {
            setInputValue(option.render);
        } else {
            setInputValue(option.label);

            setInputIcon(option.icon);
        }

        // inputRef.current?.blur(); // Doesn't work for now cause of asynchronous state change, which doesn't update the value of input in time
        setFocused(false);

        // Reset search value
        setSearchValue('');
        if (option.id) {
            setInputId(option.id);
            if (onChange) onChange((option.value as string) || option || '');
        } else if (onChange) onChange((option.value as string) || option.label || '');
    };
    return (
        <div
            className={`dropdown ${className ?? ''}`}
            style={fullWidth ? { width: '100%' } : {}}
            tabIndex={-1}
            onKeyDown={handleKeyDown}>
            <div
                className={`select--container ${isActive ? 'select--active' : ''} ${
                    isFocused ? 'select--focused select--opened' : ''
                } p-075`}
                onClick={() => focusInput()}>
                <div className="select--wrapper">
                    <div className="select--label-wrapper">
                        <label>{label}</label>
                    </div>
                    {inputIcon && (
                        <div className="avatar">
                            <img
                                src={`https://profile-images-exclusively-s3-prd.s3.amazonaws.com/${inputIcon}`}
                                // width="100%"
                                style={{ width: '2.3rem', height: '2.3rem', borderRadius: '100%' }}
                            />
                        </div>
                    )}
                    <div className="select--input--wrapper">
                        {slotLeft && <div className="select--leftSlot">{slotLeft}</div>}
                        <input
                            ref={inputRef}
                            placeholder={!isActive ? label : ''}
                            id={id}
                            name={name}
                            type={type ? type : 'text'}
                            onChange={event => handleInputChange(event)}
                            value={inputValue}
                            className={className}
                            disabled={disabled}
                            readOnly={readOnly}
                            autoComplete="none"
                            onBlur={e => unfocusInput(e)}
                        />
                    </div>
                    <div className="select--icon">
                        <i className="fal fa-chevron-down"></i>
                    </div>
                </div>
            </div>

            {isFocused && (
                <div className="dropdown--content" ref={dropdownRef}>
                    <div className="dropdown--wrapper">
                        <ul>
                            {options
                                ?.filter(option => {
                                    if (
                                        option.label
                                            .toLowerCase()
                                            .indexOf(searchValue.toLowerCase()) === -1
                                    ) {
                                        return false;
                                    }

                                    return true;
                                })
                                .map((option: any, i: number) => {
                                    const isOptionFocused = focusedOption === option;
                                    return (
                                        <li key={i}>
                                            <div
                                                className={`dropdown-item-list ${
                                                    inputId === option.id ? 'item--selected' : ''
                                                } ${isOptionFocused ? 'item--focused' : ''}`}
                                                onClick={() => selectOption(option)}
                                                ref={
                                                    isOptionFocused ? focusedOptionRef : undefined
                                                }>
                                                {option.icon && (
                                                    <div className="avatar">
                                                        <img
                                                            src={`https://profile-images-exclusively-s3-prd.s3.amazonaws.com/${option.icon}`}
                                                            style={{
                                                                width: '2.3rem',
                                                                height: '2.3rem',
                                                                borderRadius: '100%',
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                                <div className="item--text" id={option.id}>
                                                    {option.label}
                                                </div>
                                            </div>
                                        </li>
                                    );
                                })}
                        </ul>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Input;
