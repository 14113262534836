import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';
import { palette } from 'styled/common';
import SendMessage from '../SendMessage';
import { api } from 'helpers/auth-axios';
import { useSnackbar } from '../Snackbar';
import useRouter from 'hooks/router';
import Alert from '../Alert/Alert';
import { IAccount } from 'interfaces/stripe.interface';
import { RoleEnum } from 'constants/enums';
import WarningModal from '../WarningModal';

export interface CardProps {
    link?: string;
    nameData?: string;
    name?: string;
    phone?: string;
    email?: string;
    serviceProviderBookingLink?: string;
    model?: any;
    button?: boolean;
    appointment?: boolean;
    totalAppointments?: any;
    revenue?: boolean;
    totalRevenue?: any;
    totalTip?: any;
    profileImage?: any;
    checkStaff?: any;
    submit?: any;
    stripe_account_verified?: any;
    stripeId?: any;
    square_account_verified?: any;
    showRevokeSquareTokenButton?: boolean;
    removeAccount?: any;
    tip?: any;
    accountObject?: any; // the account whose info will be shown in Card
    reloadAccountObject?: any;
    accountRole: string; // pass the role (or "type") of account being shown
}

const Card = ({
    link,
    nameData,
    name,
    phone,
    email,
    serviceProviderBookingLink,
    model,
    button,
    appointment,
    totalAppointments,
    revenue,
    totalRevenue,
    totalTip,
    profileImage,
    checkStaff,
    submit,
    stripe_account_verified,
    stripeId,
    square_account_verified,
    showRevokeSquareTokenButton,
    removeAccount,
    tip,
    accountObject,
    reloadAccountObject,
    accountRole,
}: CardProps) => {
    var _userData = JSON.parse(localStorage.getItem('user') || '{}');
    const [isSendMessage, setIsSendMessage] = useState(false);
    const [modalState, setModalState] = useState({
        show: false,
        message: '',
        continueHandler: () => {},
        closeHandler: () => {},
    });

    // state variable(s) for "Stripe account restricted" alert
    const [stripeWarningMessage, setStripeWarningMessage] = useState<React.ReactNode>();

    const [openSnackbar] = useSnackbar();
    const router = useRouter();

    useEffect(() => {
        // if we're here, the service provider must've changed, so reset the Stripe warning message
        setStripeWarningMessage(undefined);

        // the "Stripe account restricted" message should only be used here for business owner accounts
        if (_userData.user_login !== 'user') {
            return;
        }

        // make sure the current service provider being shown has an active Stripe integration
        if (!stripe_account_verified || !stripeId || stripeId === '') {
            return;
        }

        api.get(`/billing/account/${stripeId}`)
            .then((res: any) => {
                const account = res?.data;
                const disabledReason = account?.requirements?.disabled_reason;
                // if disabled_reason is not null, then the account is disabled
                if (disabledReason) {
                    setStripeWarningMessage(
                        <>
                            {name}'s Stripe account is currenty disabled. Please have them{' '}
                            <a
                                href="https://dashboard.stripe.com/login"
                                target="_blank"
                                style={{ textDecoration: 'underline' }}>
                                login to their Stripe account
                            </a>{' '}
                            and provide any missing information to continue receiving payments.
                        </>
                    );
                }
            })
            .catch((err: any) => {
                console.error('There was a problem fetching Stripe account info:', err);
            });
    }, [stripeId]);

    /*
     * Handle the payment processor (Stripe, Square) OAuth flow responses
     */
    useEffect(() => {
        if (router.query.status === 'stripe_return' && router.query.account_id) {
            /*
             * Handle Stripe Auth Return
             */
            router.navigate(`/team/service-provider-list/${submit._id}`);
            api.get(`/billing/account/${router.query.account_id}`).then(res => {
                const account: IAccount = res.data;
                // Not fully completed -> show error message
                if (!account.details_submitted) {
                    openSnackbar('Failed to integrate with Stripe. Please try again');
                } else {
                    integrateStripeAccount(true);
                }
            });
        } else if (
            router.query.response_type &&
            router.query.response_type == 'code' &&
            router.query.code &&
            router.query.state
        ) {
            /*
             * Handle Square OAuth Return
             */
            const CSRF_TOKEN = localStorage.getItem('squareOAuthCSRF');
            if (!CSRF_TOKEN) {
                return;
            }
            // make sure the returned CSRF token matches the one we've stored in the session
            if (CSRF_TOKEN == router.query.state) {
                // send authorization code to the backend, so it can grab the
                // access and refresh tokens
                const authorizationCode = router.query.code;
                api.post(`/serviceProvider/${submit._id}/connect-to-square`, {
                    authorizationCode: authorizationCode,
                })
                    .then(res => {
                        localStorage.removeItem('squareOAuthCSRF');
                        openSnackbar('Square integrated successfully!');
                        reloadAccountObject();
                    })
                    .catch((e: any) => {
                        if (e?.response) {
                            openSnackbar(e?.response?.data?.message);
                        }
                        reloadAccountObject();
                    });
            }
        }
    }, [router.query]);

    const integrateStripeAccount = (value: any) => {
        api.put(`/serviceProvider/service_provider/${submit._id}`, {
            stripe_account_verified: value,
        })
            .then((res: any) => {
                openSnackbar('Stripe Account Integrated Successfully!');
                router.navigate(`/team/service-provider-list/${submit._id}`);
                reloadAccountObject();
            })
            .catch((e: any) => {
                openSnackbar('Failed to integrate with Stripe. Please try again');
            });
    };

    const activateAccount = () => {
        if (!accountObject || !reloadAccountObject) {
            return;
        }

        let uri;
        if (accountRole === RoleEnum.SERVICEPROVIDER) {
            uri = '/serviceProvider';
        } else if (accountRole === RoleEnum.TEAMMEMBER) {
            uri = '/team';
        } else if (accountRole === RoleEnum.OTHERS) {
            uri = '/other';
        }

        api.put(`${uri}/${accountObject._id}/reactivate`)
            .then((res: any) => {
                if (res.data.message) {
                    reloadAccountObject();
                    openSnackbar('Successfully reactivated user!');
                } else {
                    openSnackbar('Failed to reactivate user... Please try again later.');
                }
            })
            .catch((e: any) => {
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    const deactivateAccount = () => {
        if (!accountObject || !reloadAccountObject) {
            return;
        }

        let uri;
        if (accountRole === RoleEnum.SERVICEPROVIDER) {
            uri = '/serviceProvider';
        } else if (accountRole === RoleEnum.TEAMMEMBER) {
            uri = '/team';
        } else if (accountRole === RoleEnum.OTHERS) {
            uri = '/other';
        }

        api.put(`${uri}/${accountObject._id}/deactivate`)
            .then((res: any) => {
                if (res.data.message) {
                    reloadAccountObject();
                    openSnackbar('Successfully deactivated user!');
                } else {
                    openSnackbar('Failed to deactivate user... Please try again later.');
                }
            })
            .catch((e: any) => {
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    return (
        <>
            <MainCard>
                {stripeWarningMessage && stripeWarningMessage !== '' && (
                    <Alert
                        type="error"
                        title="Stripe Account Restricted"
                        onClose={() => {
                            setStripeWarningMessage(undefined);
                        }}>
                        {stripeWarningMessage}
                    </Alert>
                )}
                <Icons>
                    {_userData.user_login == 'customer' && (
                        //<AddIcon to="/customer/appointment">New Appointment</AddIcon>
                        <></>
                    )}
                    <EditIcon to={`${link}`}>
                        <i className="fal fa-pen"></i>
                    </EditIcon>
                    {(accountRole === RoleEnum.SERVICEPROVIDER ||
                        accountRole === RoleEnum.TEAMMEMBER ||
                        accountRole === RoleEnum.OTHERS) &&
                        _userData.user_login === RoleEnum.USER && (
                            <>
                                {accountObject.active !== false ? (
                                    <BasicButton
                                        onClick={() => {
                                            setModalState({
                                                show: true,
                                                message:
                                                    'Are you sure you want to deactivate this user?',
                                                continueHandler: () => {
                                                    deactivateAccount();
                                                    setModalState((prevState: any) => ({
                                                        ...prevState,
                                                        show: false,
                                                    }));
                                                },
                                                closeHandler: () => {
                                                    setModalState((prevState: any) => ({
                                                        ...prevState,
                                                        show: false,
                                                    }));
                                                },
                                            });
                                        }}>
                                        <i className="fal fa-lock-open"></i>
                                    </BasicButton>
                                ) : (
                                    <BasicButton
                                        onClick={() => {
                                            setModalState({
                                                show: true,
                                                message:
                                                    'Are you sure you want to reactivate this user?',
                                                continueHandler: () => {
                                                    activateAccount();
                                                    setModalState((prevState: any) => ({
                                                        ...prevState,
                                                        show: false,
                                                    }));
                                                },
                                                closeHandler: () => {
                                                    setModalState((prevState: any) => ({
                                                        ...prevState,
                                                        show: false,
                                                    }));
                                                },
                                            });
                                        }}>
                                        <i className="fal fa-lock"></i>
                                    </BasicButton>
                                )}
                            </>
                        )}

                    {_userData.user_login == 'user' ? (
                        <DeletIcon onClick={model}>
                            <i className="fal fa-trash-alt"></i>
                        </DeletIcon>
                    ) : (
                        ''
                    )}
                </Icons>
                <CardWrapper>
                    <CardBody>
                        <CardContent>
                            <Icon>
                                <IconData
                                    src={`https://profile-images-exclusively-s3-prd.s3.amazonaws.com/${profileImage}`}></IconData>
                            </Icon>
                            <Name> {name}</Name>
                            <Email>{email}</Email>
                            <Phone>{phone}</Phone>
                            {serviceProviderBookingLink && (
                                <div>
                                    <CopyToClipboard
                                        text={serviceProviderBookingLink}
                                        onCopy={() => {
                                            openSnackbar('Copied to clipboard');
                                        }}>
                                        <button>
                                            <Phone>
                                                Copy Booking Link <i className="far fa-copy"></i>
                                            </Phone>
                                        </button>
                                    </CopyToClipboard>
                                </div>
                            )}
                            <Appointments>
                                {appointment ? (
                                    <AppointmentsField>
                                        <TotalAppointments>
                                            {totalAppointments ? totalAppointments : 0}
                                        </TotalAppointments>
                                        <Title>Appointments</Title>
                                    </AppointmentsField>
                                ) : (
                                    ''
                                )}
                                {revenue ? (
                                    <RevenueField>
                                        <TotalAppointments>
                                            {_userData.user.business_id.currency}
                                            {totalRevenue ? parseFloat(totalRevenue).toFixed(2) : 0}
                                        </TotalAppointments>
                                        <Title>Total Revenue</Title>
                                    </RevenueField>
                                ) : (
                                    ''
                                )}
                                {tip && (
                                    <RevenueField>
                                        <TotalAppointments>
                                            {_userData.user.business_id.currency}
                                            {totalTip ? parseFloat(totalTip).toFixed(2) : 0}
                                        </TotalAppointments>
                                        <Title>Total Tip</Title>
                                    </RevenueField>
                                )}
                            </Appointments>
                        </CardContent>
                    </CardBody>
                </CardWrapper>
            </MainCard>

            {isSendMessage && (
                <SendMessage
                    name={name}
                    phone={phone}
                    senderEmail={_userData.user.email}
                    reciverEmail={email}
                    onClose={() => {
                        setIsSendMessage(false);
                    }}
                />
            )}

            {modalState.show && (
                <WarningModal
                    message={modalState.message}
                    onContinue={modalState.continueHandler}
                    onClose={modalState.closeHandler}
                />
            )}
        </>
    );
};

const MainCard = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: ${palette.light};
    background-clip: border-box;
    border-radius: 0.42rem;
    box-shadow: 0rem 0rem 2.14rem 0rem ${palette.grey.shadow};
    border: 0;
    margin-bottom: 1.5rem;
`;
const CardWrapper = styled.div`
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
    display: flex !important;
    padding: 1.75rem 2rem;
    padding-top: 0px !important;
`;
const Icons = styled.div`
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    display: flex;
    justify-content: flex-end;
`;
const EditIcon = styled(Link)`
    padding: 0;
    width: 2.85rem;
    height: 2.85rem;
    display: flex;
    align-items: center;
    border-width: 0.07rem solid ${palette.black};
    justify-content: center;
    background-color: ${palette.black};
    color: ${palette.light};
    margin-right: 1rem;
    font-weight: 600;
    text-transform: capitalize;
    border-radius: 0.51rem;
    font-size: 1.2rem;
`;
const DeletIcon = styled.button`
    padding: 0;
    width: 2.85rem;
    height: 2.85rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 0rem;
    background-color: ${palette.primary};
    color: ${palette.light};
    font-weight: 600;
    text-transform: capitalize;
    border-radius: 0.51rem;
    font-size: 1.07rem;
    margin-right: 0.71rem;
    cursor: pointer;
`;

const BasicButton = styled.button`
    padding: 0 0.7rem;
    width: 2.85rem;
    height: 2.85rem;
    display: flex;
    align-items: center;
    border-width: 0.07rem solid ${palette.black};
    justify-content: center;
    background-color: ${palette.black};
    color: ${palette.light};
    margin-right: 1rem;
    font-weight: 600;
    text-transform: capitalize;
    border-radius: 0.51rem;
    font-size: 1.2rem;
`;

const CardBody = styled.div`
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    display: flex;
    justify-content: flex-end;
`;
const CardContent = styled.div`
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
    display: flex !important;
`;

const Icon = styled.div`
    display: inline-block;
    flex-shrink: 0;
    position: relative;
    border-radius: 0.42rem;
`;
const IconData = styled.img`
    background: linear-gradient(-135deg, ${palette.grey.light}, ${palette.grey.lightest});
    color: ${palette.silver};
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    line-height: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 0.42rem;
    font-size: 1.21rem;
    text-transform: capitalize !important;
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
`;
const Name = styled.div`
    font-weight: 600 !important;
    font-size: 1.5rem !important;
    line-height: 2rem !important;
    margin-top: 0.75rem !important;
`;
const Email = styled.div`
    color: ${palette.silver} !important;
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
`;
const Phone = styled.div`
    color: ${palette.silver} !important;
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
`;
const Appointments = styled.div`
    display: flex;
    margin-top: 1rem;
    margin-bottom: 1rem;
`;
const AppointmentsField = styled.div`
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-right-width: 0.07rem;
`;
const TotalAppointments = styled.div`
    font-weight: 600 !important;
    font-size: 1.125rem !important;
    line-height: 1.75rem !important;
    text-align: center !important;
`;
const Title = styled.div`
    color: ${palette.dark} !important;
    font-size: 0.75rem !important;
    line-height: 1rem !important;
    text-align: center !important;
`;

const RevenueField = styled.div`
    padding-left: 1.5rem;
    padding-right: 1.5rem;
`;

export default Card;
