import { useEffect, useState } from 'react';
import { ICustomer } from 'interfaces/customer.interface';
import { IService } from 'interfaces/service.interface';

import SelectClient from '@components/appointments/NewAppointmentDrawer/SelectClient';
import EmptyBasket from './EmptyBasket';
import BasketItems from './BasketItems';
import { Col, media } from 'styled-bootstrap-grid';
import styled from 'styled-components';

const BasketSidebar = ({
    customer,
    items,
    step,
    subtotal,
    gratuity,
    onNext,
    onSelectCustomer,
    onCharge,
    paymentMethod,
}: {
    customer?: ICustomer;
    items: IService[];
    step: number;
    subtotal: any;
    gratuity: any;
    onNext(): void;
    onCharge(): void;
    onSelectCustomer(customer: ICustomer): void;
    paymentMethod?: any;
}) => {
    const [customerView, setCustomerView] = useState(false);

    const disabledBtn = !items.length || !customer;
    var _userData = JSON.parse(localStorage.getItem('user') || '{}');

    const total: any = gratuity
        ? parseFloat(gratuity) + parseFloat(subtotal)
        : 0 + parseFloat(subtotal);
    return (
        <>
            <Wrapper md={5} lg={4}>
                {!customerView && (
                    <>
                        <Header>
                            {customer ? (
                                <div>
                                    <CustomersSelect
                                        onClick={() => {
                                            setCustomerView(true);
                                        }}>
                                        <CustomerIcon>
                                            <Icon
                                                src={`https://profile-images-exclusively-s3-prd.s3.amazonaws.com/${customer?.photo}`}></Icon>
                                        </CustomerIcon>
                                        <CustomerContent>
                                            <CusomerName>
                                                {customer.firstname + ' ' + customer.lastname}
                                            </CusomerName>
                                            <CustomerEmail>{customer.phone}</CustomerEmail>
                                            <CustomerEmail>{customer.email}</CustomerEmail>
                                        </CustomerContent>
                                        <div>
                                            <RightArrow className="fal fa-chevron-right"></RightArrow>
                                        </div>
                                    </CustomersSelect>
                                </div>
                            ) : (
                                <CustomersSelect
                                    onClick={() => {
                                        setCustomerView(true);
                                    }}>
                                    <CustomerIcon>
                                        <Icon></Icon>
                                    </CustomerIcon>
                                    <SelectAClient>Select a client</SelectAClient>
                                    <div>
                                        <RightArrow className="fal fa-plus"></RightArrow>
                                    </div>
                                </CustomersSelect>
                            )}
                        </Header>
                        <BasketBody>
                            {items.length ? (
                                <BasketItems
                                    items={items}
                                    subtotal={subtotal}
                                    gratuity={gratuity}
                                    paymentMethod={paymentMethod}
                                />
                            ) : (
                                <EmptyBasket />
                            )}
                        </BasketBody>
                        <BasketFooter>
                            <PriceContent>
                                <TotalPriceText>Total</TotalPriceText>
                                <Price>
                                    {_userData.user.business_id.currency}
                                    {total.toFixed(2)}
                                </Price>
                            </PriceContent>
                            {step === 1 ? (
                                <Button
                                    className={`${disabledBtn ? 'disabled' : ''}`}
                                    onClick={onNext}
                                    disabled={disabledBtn}>
                                    Continue
                                </Button>
                            ) : (
                                <Button onClick={onCharge}>
                                    {paymentMethod !== undefined &&
                                    (paymentMethod === 'paypal' ||
                                        paymentMethod === 'american_express')
                                        ? 'Next'
                                        : 'Charge'}
                                </Button>
                            )}
                        </BasketFooter>
                    </>
                )}
                {customerView && (
                    <SelectClient
                        onClose={() => {
                            setCustomerView(false);
                        }}
                        onSelect={(customer: ICustomer) => {
                            onSelectCustomer(customer);
                        }}
                        height={true}
                    />
                )}
            </Wrapper>
        </>
    );
};

const Wrapper = styled(Col)`
    position: absolute;
    top: 0;
    right: 0;
    padding: 0rem;
    height: 100%;
    background-color: #fff;
    z-index: 99;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    ${media.xs`
  position: relative;
  `}
    ${media.sm`
  position: relative;

  `}
    ${media.md`
    position: absolute;
    top: 0;
    right: 0;

  `}
    ${media.lg`
    position: absolute;
    top: 0;
    right: 0;
  `}
`;
const Header = styled.div`
    padding: 20px;
`;

const CustomersSelect = styled.div`
    display: flex;
    align-items: center;
    box-shadow: none;
    background: #fff;
    padding: 1rem 16px;
    border-radius: 0.5rem;
    width: 100%;
    border: 1px solid rgba(223, 226, 230);
    cursor: pointer;
`;

const CustomerIcon = styled.div`
    display: inline-block;
    flex-shrink: 0;
    position: relative;
    border-radius: 0.42rem;
    border-radius: 50%;
`;
const Icon = styled.img`
    background: linear-gradient(-135deg, #eee, #dddfe1);
    color: #7e8299;
    width: 3.5rem;
    height: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    line-height: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 50%;
    font-size: 17px;
    text-transform: capitalize;
`;
const CustomerContent = styled.div`
    margin-left: 1rem /* 16px */;
    margin-right: 1rem /* 16px */;
    flex: 1 1 0%;
    flex-direction: column;
`;
const CusomerName = styled.div`
    font-weight: 500;
`;
const CustomerEmail = styled.div`
    color: rgba(157, 160, 164) !important;
    font-size: 0.9rem !important;
`;
const RightArrow = styled.i`
    font-size: 1.125rem /* 18px */;
    line-height: 1.75rem /* 28px */;
`;
const SelectAClient = styled.div`
    margin-left: 1rem /* 16px */;
    margin-right: 1rem /* 16px */;
    flex: 1 1 0%;
`;
const BasketFooter = styled.div`
    padding: 0 20px 20px;
`;
const BasketBody = styled.div`
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
`;

const PriceContent = styled.div`
    display: flex !important;
    margin-bottom: 1rem !important;
    align-items: center !important;
    justify-content: space-between !important;
`;
const TotalPriceText = styled.div`
    color: rgba(188, 192, 197) !important;
    text-transform: capitalize !important;
    font-weight: 500 !important;
    font-size: 0.9rem !important;
`;
const Price = styled.div`
    font-size: 1.5rem !important;
    line-height: 2rem !important;
    font-weight: 600 !important;
    text-align: right !important;
`;

const Button = styled.button`
    width: 100%;
    padding: 13px 26px;
    font-weight: 600;
    text-transform: capitalize;
    color: #fff;
    background: #27292e;
    border-radius: 8px;
    font-size: 13px;
    border: none;
    cursor: pointer;
`;

export default BasketSidebar;
